<template>

  <button class="solicitar-contato-orcamento">
      {{titulo}}
  </button>
    
</template>

<script>

export default {
  name: 'Button-solicitar-orcamento',
  props: [
      'titulo'
  ]
 
}

</script>

<style scoped>

.solicitar-contato-orcamento {
  margin-top: 15px;
  height: 32px;
  width: 234px;
  margin-bottom: 76px;
}

</style>