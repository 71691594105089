<template>
    <section id="desktop">
        <div class="col-12 col-md-3 col-sm-3 col-lg-3 col-xl-3 gap-3">
            
            <div class="card" style="width: 18rem;">
                <img src="@/assets/img/rapida.svg" class="card-img-top" alt="...">
                <div class="card-body">
                    <h5 class="card-title">Entrega</h5>
                    <p class="card-text">Rápida e<br>Confiável</p>
                </div>
            </div>

        </div>

        <div class="col-12 col-md-3 col-sm-3 col-lg-3 col-xl-3 gap-3">

            <div class="card" style="width: 18rem;">
                <img src="@/assets/img/cartao.svg" class="card-img-top" alt="...">
                <div class="card-body">
                    <h5 class="card-title">Pague Fácil</h5>
                    <p class="card-text">Cartão<br>BNDES</p>
                </div>
            
            </div>
        </div>    

        <div class="col-12 col-md-3 col-sm-3 col-lg-3 col-xl-3 gap-3">

            <div class="card" style="width: 18rem;">
                <img src="@/assets/img/foguete.svg" class="card-img-top" alt="...">
                <div class="card-body">
                    <h5 class="card-title">Produtos Modernos</h5>
                    <p class="card-text">Tecnologia e inovação<br>nos processos</p>
                </div>
            
            </div>
            
        </div>
        <div class="col-12 col-md-3 col-sm-3 col-lg-3 col-xl-3 gap-3">

            <div class="card" style="width: 18rem;">
                <img src="@/assets/img/atendimento.svg" class="card-img-top" alt="...">
                <div class="card-body">
                    <h5 class="card-title">Suporte ágil</h5>
                    <p class="card-text">Atendimento<br>focado no cliente</p>
                </div>
            
            </div>
            
        </div>
    </section>
    <section id="mobile">
        <div class="col-12 d-flex justify-content-center">
            
            <div class="card" style="width: 18rem;">
                <img src="@/assets/img/rapida.svg" class="card-img-top" alt="...">
                <div class="card-body">
                    <h5 class="card-title">Entrega</h5>
                    <p class="card-text">Rápida e<br>Confiável</p>
                </div>
            </div>

        </div>

        <div class="col-12 d-flex justify-content-center">

            <div class="card" style="width: 18rem;">
                <img src="@/assets/img/cartao.svg" class="card-img-top" alt="...">
                <div class="card-body">
                    <h5 class="card-title">Pague Fácil</h5>
                    <p class="card-text">Cartão<br>BNDES</p>
                </div>
            
            </div>
        </div>    

        <div class="col-12 d-flex justify-content-center">

            <div class="card" style="width: 18rem;">
                <img src="@/assets/img/foguete.svg" class="card-img-top" alt="...">
                <div class="card-body">
                    <h5 class="card-title">Produtos Modernos</h5>
                    <p class="card-text">Tecnologia e inovação<br>nos processos</p>
                </div>
            
            </div>
            
        </div>
        <div class="col-12 d-flex justify-content-center">

            <div class="card" style="width: 18rem;">
                <img src="@/assets/img/atendimento.svg" class="card-img-top" alt="...">
                <div class="card-body">
                    <h5 class="card-title">Suporte ágil</h5>
                    <p class="card-text">Atendimento<br>focado no cliente</p>
                </div>
            
            </div>
            
        </div>
    </section>
</template>

<script>
export default {
    name: 'Suporte'
}
</script>

<style scoped>

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff0;
    background-clip: border-box;
    border: 0px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
}
.card-img-top {
    width: 97px;
    height: 97px;
    margin-left: auto;
    margin-right: auto;
}
.card-title {
    color: #FF8523;
    font-size: 20px;
    font-weight: 700;
    line-height: 29px;
    text-align: center;
    text-transform: uppercase;

}
.card-text {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
}
@media (min-width: 1441px) { 

  #mobile {
    display: none;
  }
  #desktop {
    display: flex;
  }

 }
@media (max-width: 1440px) { 

  #mobile {
    display: none;
  }
  #desktop {
    display: flex;
  }

 }
@media (max-width: 1399px) { 
  #mobile {
    display: none;
  }
  #desktop {
    display: flex;
  }
 }
@media (max-width: 1199px) { 
  #mobile {
    display: none;
  }
  #desktop {
    display: flex;
  }
 }
@media (max-width: 992px) { 
  #mobile {
    display: none;
  }
  #desktop {
    display: flex;
  }
 }
@media (max-width: 768px) {
  #mobile {
    display: block;
  }
  #desktop {
    display: none;
  }
  }
@media (max-width: 575.98px) { 
  
  #mobile {
    display: block;
  }
  #desktop {
    display: none;
  }
 }
@media (max-width: 360px) { 
  #mobile {
    display: block;
  }
  #desktop {
    display: none;
  }

 }


</style>