<template>

<nav class="navbar navbar-light bg-white topbar">
  <div class="container d-flex justify-content-center">
    <div class="row">
        <a class="navbar-brand" href="/">
          <img src="@/assets/img/logoheader.svg" alt="" width="62" height="58">
        </a>
    </div>
    
  </div>
</nav>

</template>

<script>
export default {
    name: 'TopBar',
}
</script>

<style scoped>

  .topbar {
    border-bottom-right-radius: 80px;
  } 

/* Media queries */

@media (max-width: 1440px) {  }
@media (max-width: 1399px) {  }
@media (max-width: 1199px) {  }
@media (max-width: 992px) {  }
@media (max-width: 768px) {  }
@media (max-width: 575.98px) { 
 
   .topbar {
    border-bottom-right-radius: 0px;
  }
 }
@media (max-width: 360px) { 
   .topbar {
    border-bottom-right-radius: 0px;
  }
 }


</style>