<template>

  <button class="solicitar-contato">
      {{titulo}}
  </button>
    
</template>

<script>

export default {
  name: 'Button-solicitar-contato',
  data: () => ({
      url: 'https://wa.me/5537998015203'
    }),
  props: [
      'titulo'
  ]
 
}

</script>

<style scoped>

.solicitar-contato {
  margin-top: 40px;
  height: 32px;
  width: 296px;
}

</style>