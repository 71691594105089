<template>
    <section class="header align-items-end" id="desktop1">
        <div class="container">
            <div class="row d-flex align-items-end" style="height: 100vh;">
                <div class="col-12 col-md-6 col-sm-6 col-lg-6 col-xl-6 rectangle align-items-end justify-content-center">
                    <p class="h1-text">
                        Garanta a armazenagem de<br>várias mercadorias de forma<br>organizada em espaços<br>reduzidos.
                    </p>
                    <span class="subtext-header">
                        Aumente a capacidade do seu estoque em até 4,4 vezes com os Porta Pallets da Amapá e encaixe até 784 pallets em um galpão de 500m².
                    </span>
                    <a :href='url'><ButtonSolicitarContatoVue id="button"
                    titulo="solicitar contato da Amapá"
                    /></a>
                    <a :href='url'><ButtonSolicitarOrcamento id="button-orcamento" 
                    titulo="SOLICITAR ORCAMENTO"
                    /></a>
                </div>
                <div class="col-12 col-md-6 col-sm-6 col-lg-6 col-xl-6 justify-content-end">
                    <p class="titulo">Ganhe+Tempo</p>
                    <p class="titulo">Ganhe + espaço</p>
                    <p class="titulo" style="padding-bottom: 30px;">Ganhe + segurança</p>
                </div>
            </div>
        </div>
    </section>
    <section class="header" id="mobile">
        <div class="container ">
            <div class="row d-flex justify-content-center">
                <div class="col-12 col-md-6 col-sm-6 col-lg-6 col-xl-6 rectangle d-flex justify-content-center">
                    <p class="h1-text">
                        Garanta a armazenagem de<br>várias mercadorias de forma<br>organizada em espaços<br>reduzidos.
                    </p>
                    <span class="subtext-header">
                        <center>Aumente a capacidade do seu estoque em até 4,4 vezes com os Porta Pallets da Amapá e encaixe até 784 pallets em um galpão de 500m².</center>
                    </span>
                    <center><a :href='url'><ButtonSolicitarContatoVue id="button"
                    titulo="solicitar contato da Amapá"
                    /></a></center>
                    <center><a :href='url'><ButtonSolicitarOrcamento id="button-orcamento" 
                    titulo="SOLICITAR ORCAMENTO"
                    /></a></center>
                </div>
            </div>
        </div>
    </section>
    <section class="segunda-secao">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12">
                    <p class="text-quant">Quanto seu centro de armazenagem perde pela bagunça?</p>
                    <p class="text-quant-area">Deixe esse problema no passado utilizando os Porta Pallets:</p>
                </div>
                <div class="">
                    <CentroDistribuicao />
                </div>
            </div>
        </div>
    </section>
    <section class="terceira-secao align-items-end" id="desktop">
        <div class="container">
            <div class="row align-items-end">
                <div class="col-12 col-md-4 col-sm-4 col-lg-4 col-xl-4 rectangle1">
                    <p class="h1-text">
                        Cada Porta Pallet é ajustável ao que sua empresa precisa hoje. 
                    </p>
                    <span class="subtext-header">
                        <p> Ideal para corredores estreitos, muito estreitos ou largos, esse produto permite a distância padronizada e o aproveitamento máximo de todo espaço disponível.</p>
                        <p>Feito de aço e com materiais de qualidade superior, proporciona resistência e durabilidade e suporta até toneladas de carga.</p>
                        <p>A pintura eletrostática e o tratamento anticorrosãouniforme torna a estrutura ideal para diversascondições de temperatura e umidade.</p>
                    </span>
                    <span class="text-area">
                        <p>Todos os projetos passam por inspeções e seguem todas as norma técnicas:</p>
                        <p>NBR 15524  |  NBR 14762 </p>
                    </span>
                    <a :href='url'><ButtonSolicitarContatoVue class="cam" id="button"
                    titulo="solicitar contato da Amapá"
                    /></a>
                </div>
                <div class="col-12 col-md-8 col-sm-8 col-lg-8 col-xl-8 justify-content-end">
                    
                </div>
            </div>
        </div>
    </section>
    <section class="terceira-secao" id="mobile">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-4 col-sm-12 col-lg-4 col-xl-4">
                    <p class="h1-text">
                        Cada Porta Pallet é ajustável ao que sua empresa precisa hoje. 
                    </p>
                    <span class="subtext-header">
                        <p> Ideal para corredores estreitos, muito estreitos ou largos, esse produto permite a distância padronizada e o aproveitamento máximo de todo espaço disponível.</p>
                        <p>Feito de aço e com materiais de qualidade superior, proporciona resistência e durabilidade e suporta até toneladas de carga.</p>
                        <p>A pintura eletrostática e o tratamento anticorrosãouniforme torna a estrutura ideal para diversascondições de temperatura e umidade.</p>
                    </span>
                    <span class="text-area">
                        <p>Todos os projetos passam por inspeções e seguem todas as norma técnicas:</p>
                        <p>NBR 15524  |  NBR 14762 </p>
                    </span>
                    <center><a :href='url'><ButtonSolicitarContatoVue class="cam" id="button"
                    titulo="solicitar contato da Amapá"
                    /></a></center>
                </div>
            </div>
        </div>
    </section>
    <section class="quarta-secao" id="desktop">
        <div class="container-fluid">
            <div class="row">
                    <div class="col-12 col-md-5 col-sm-5 col-lg-5 col-xl-5 d-flex align-items-center grafismo-esquerdo">
                        <div class="internas">
                            Do projeto à montagem, a Amapá resolve sua demanda de armazenagem.
                            <div class="interna">
                                <ul>
                                    <li>
                                        centros de distribuição 
                                    </li>
                                    <li>
                                        indústrias 
                                    </li>
                                    <li>
                                        atacarejos
                                    </li>
                                    <li>
                                        supermercados 
                                    </li>
                                    <li>
                                        farmácias 
                                    </li>
                                    <li>
                                        pet shops 
                                    </li>
                                    <li>
                                        home centers
                                    </li>
                                </ul>
                            </div>
                        </div>    
                    </div>
                    <div class="col-12 col-md-7 col-sm-7 col-lg-7 col-xl-7 grafismo-direito">
                        <div class="internas">
                            Estoque eficaz para pequenas, médias e grandes empresas de diversos segmentos: 
                            <div class="interna2">
                                <ul>
                                    <li>
                                        centros de distribuição 
                                    </li>
                                    <li>
                                        indústrias 
                                    </li>
                                    <li>
                                        atacarejos
                                    </li>
                                    <li>
                                        supermercados 
                                    </li>
                                    <li>
                                        farmácias 
                                    </li>
                                    <li>
                                        pet shops 
                                    </li>
                                    <li>
                                        home centers
                                    </li>
                                </ul>
                            </div>
                        </div>    
                    </div>
            </div>
        </div>
    </section>
    <section class="quarta-secao" id="mobile">
        <div class="container-fluid">
            <div class="row">
                    <div class="col-12 col-md-5 col-sm-12 col-lg-5 col-xl-5 d-flex align-items-center grafismo-esquerdo">
                        <div class="internas">
                            Do projeto à montagem, a Amapá resolve sua demanda de armazenagem.
                            <div class="interna">
                                <ul>
                                    <li>
                                        centros de distribuição 
                                    </li>
                                    <li>
                                        indústrias 
                                    </li>
                                    <li>
                                        atacarejos
                                    </li>
                                    <li>
                                        supermercados 
                                    </li>
                                    <li>
                                        farmácias 
                                    </li>
                                    <li>
                                        pet shops 
                                    </li>
                                    <li>
                                        home centers
                                    </li>
                                </ul>
                            </div>
                        </div>    
                    </div>
                    <div class="col-12 col-md-7 col-sm-12 col-lg-7 col-xl-7 grafismo-direito">
                        <div class="internas">
                            Estoque eficaz para pequenas, médias e grandes empresas de diversos segmentos: 
                            <div class="interna2">
                                <ul>
                                    <li>
                                        centros de distribuição 
                                    </li>
                                    <li>
                                        indústrias 
                                    </li>
                                    <li>
                                        atacarejos
                                    </li>
                                    <li>
                                        supermercados 
                                    </li>
                                    <li>
                                        farmácias 
                                    </li>
                                    <li>
                                        pet shops 
                                    </li>
                                    <li>
                                        home centers
                                    </li>
                                </ul>
                            </div>
                        </div>    
                    </div>
            </div>
        </div>
    </section>
    <section class="quinta-secao" id="desktop">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-6 col-sm-6 col-lg-6 col-xl-6 gap-3">

                        <h2>Como a Amapá ajuda nos seus negócios?</h2>

                        <p>A Amapá entrega qualidade há mais de 38 anos, sendo referência em soluções de armazenagem inteligente para clientes de todos os estados brasileiros e outros países.</p>

                        <p>Localizada em Minas Gerais, é uma indústria brasileira feita de pessoas motivadas a encontrar as alternativas de sucesso em cada empreendimento.</p>

                        <p>Além disso, conta com a melhor equipe de especialistas em projetos que resolvem questões presentes em qualquer organização.</p>

                </div>
                <div class="col-12 col-md-6 col-sm-6 col-lg-6 col-xl-6 gap-3">
                    <a :href='url'><ButtonSolicitarContatoVue id="button2" titulo="SOLICITAR CONTATO DA AMAPÁ" /></a>
                    <p>A Amapá possui linhas completas de produtos para atender ao empreendedor com soluções para <strong>exposição, organização e armazenagem.</strong></p>

                    <p>Os equipamentos resolvem as principais questões presentes da entrada do estabelecimento ao estoque: tudo o que o empreendedor precisar.</p>

                    <p>Sem contar as diversas opções de cores e acessórios para deixar o estabelecimento com a cara da sua marca!</p>
                </div>
            </div>
        </div>
    </section>
    <section class="quinta-secao" id="mobile">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-6 col-sm-12 col-lg-6 col-xl-6 gap-3">

                        <h2>Como a Amapá ajuda nos seus negócios?</h2>

                        <p>A Amapá entrega qualidade há mais de 38 anos, sendo referência em soluções de armazenagem inteligente para clientes de todos os estados brasileiros e outros países.</p>

                        <p>Localizada em Minas Gerais, é uma indústria brasileira feita de pessoas motivadas a encontrar as alternativas de sucesso em cada empreendimento.</p>

                        <p>Além disso, conta com a melhor equipe de especialistas em projetos que resolvem questões presentes em qualquer organização.</p>

                </div>
                <div class="col-12 col-md-6 col-sm-12 col-lg-6 col-xl-6 gap-3">
                    <p>A Amapá possui linhas completas de produtos para atender ao empreendedor com soluções para <strong>exposição, organização e armazenagem.</strong></p>

                    <p>Os equipamentos resolvem as principais questões presentes da entrada do estabelecimento ao estoque: tudo o que o empreendedor precisar.</p>

                    <p>Sem contar as diversas opções de cores e acessórios para deixar o estabelecimento com a cara da sua marca!</p>
                    <center><a :href='url'><ButtonSolicitarContatoVue id="button2" titulo="SOLICITAR CONTATO DA AMAPÁ" /></a></center>
                </div>
            </div>
        </div>
    </section>
    <section class="sexta-secao">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12">
                    <h2 class="suporte">O suporte certo para seus negócios.</h2>
                </div>
                <Suporte />
            </div>
                <div class="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12">
                    <center><a :href='url'><ButtonSolicitarContatoVue id="suporte" 
                    titulo="Solicitar contato da Amapá"
                    /></a></center>
                </div>
        </div>
    </section>
</template>

<script>
import ButtonSolicitarContatoVue from '@/components/Button-solicitar-contato.vue';
import ButtonSolicitarOrcamento from '@/components/Button-solicitar-orcamento.vue';
import CentroDistribuicao from '@/components/CentroDistribuicao.vue';
import Suporte from '@/components/Suporte.vue';
export default {
    name: 'Conteudo',
    data: () => ({
      url: 'https://wa.me/5537998015203'
    }),
    components: {
    ButtonSolicitarContatoVue,
    ButtonSolicitarOrcamento,
    CentroDistribuicao,
    Suporte
    }
}
</script>

<style scoped>

    .header {
        background-image: url('@/assets/img/header.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 100vh;
        margin-top: -90px;
    }
    .rectangle {
        background-color: #FF8523;
        width: 432px;
        height: 440px;
        border-top-right-radius: 80px;
        padding: 49px 50px 50px 70px;
    }
    .rectangle1 {
        background-color: #FF8523;
        width: 619px;
        height: 598px;
        border-top-right-radius: 80px;
        padding: 100px 50px 50px 34px;
    }
    .h1-text {
        font-size: 20px;
        font-weight: 700;
        line-height: 23px;
        color: #fff;
    }
    .subtext-header {
        font-size: 16px;
        font-weight: 400;
        line-height: 18px;
        color: #fff;
    }

    #button {
        color: #FF8523;
        font-size: 16px;
        font-weight: 700;
        background-color: #fff;
        border: 0px;
        text-transform: uppercase;
        display: block;
    }
    #button2 {
        color: #FF8523;
        font-size: 16px;
        font-weight: 700;
        background-color: #fff;
        border: 0px;
        text-transform: uppercase;
        display: block;
        margin-top: 0px!important;
    }
    #button-orcamento {
        color: #ffffff;
        font-size: 16px;
        font-weight: 700;
        background-color: rgba(255, 255, 255, 0);
        border: 1px solid #fff;
        text-transform: uppercase;
        line-height: 18px;
    }
    .titulo { 
        font-size: 40px;
        font-weight: 700;
        line-height: 42px;
        text-align: right;
        color: #fff;
        margin-right: -175px;
    }

    /* segunda secao */

    .segunda-secao {
        padding: 130px 0px 90px 0px;
    }
    .text-quant {
        color: #FF8523;
        font-size: 24px;
        line-height: 35px;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
    }
    .text-quant-area {
        font-size: 18px;
        line-height: 26px;
        font-weight: 400;
        text-align: center;
        margin-bottom: 77px;
    }

    /* terceira seção */

    section.terceira-secao {
        background-image: url('@/assets/img/section3.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        padding-top: 150px;
    }
    span.text-area {
    font-weight: 700;
    font-size: 20px;
    color: #fff;
    line-height: 26px;
}
/* quarta seção */

.quarta-secao {
    padding: 0px 0px;
}

.grafismo-direito {
    background-image: url('@/assets/img/retangulo.svg');
    padding: 66px 65px 80px 160px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color: #fff;
    display: block!important;
    font-size: 20px;
    font-weight: 600;
    line-height: 29px;
}
.grafismo-esquerdo {
    padding: 66px 65px 0px 80px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: block!important;
    color: #2C2C2C;
    font-size: 20px;
    line-height: 29px;
    font-weight: 700;
    
}.interna {
    margin-top: 50px;
    border-left: 2px solid #2C2C2C;
}
.interna ul li {
    list-style: none;
    font-size: 20px;
    line-height: 38px;
    font-weight: 500;
}
.interna2{
    margin-top: 50px;
    border-left: 2px solid #ffffff;
}
.interna2 ul li {
    list-style: none;
    font-size: 20px;
    line-height: 38px;
    font-weight: 500;
}
.internas {
    padding: 0px 0px 0px 217px;
}
section.quinta-secao {
    padding: 100px 0px;
    background-image: url('@/assets/img/05.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.quinta-secao h2 {
    font-size: 20px;
    line-height: 22px;
    font-weight: 700;
    color: #fff;
}
.quinta-secao p {
    font-size: 18px;
    line-height: 26px;
    font-weight: 400;
    color: #fff;
    margin-top: 25px;
}
/* sexta secao */

.sexta-secao {
    padding: 100px 0px;
}
.suporte {
    text-transform: uppercase;
    padding-bottom: 100px;
    text-align: center;
    color: #2C2C2C;
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
}
#suporte {
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    background-color: #FF8523;
    width: 296px;
    height: 32px;
    text-transform: uppercase;
    color: #fff;
    border: 0px;
    margin: 100px 0px;
}

@media (min-width: 1440px) { 
section#desktop {
    display: flex;
}

  section#mobile {
    display: none;
  }
}
/* Media queries */

@media (max-width: 1440px) { 
    .header {
        background-image: url('@/assets/img/header.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 100% 50%;
        height: 100vh;
        margin-top: -90px;
    }
    .quinta-secao p {
    font-size: 18px;
    line-height: 26px;
    font-weight: 400;
    color: #fff;
    margin-top: 25px;
    padding-right: 50px;
}
.internas {
    padding: 0px 0px 0px 60px;
}
#mobile {
    display: none;
  }
  #desktop {
    display: flex;
  }
    }
@media (max-width: 1399px) { 
    .header {
        background-image: url('@/assets/img/header.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 100% 0%;
        height: 100vh;
        margin-top: -90px;
    }
    .segunda-secao {
        padding: 100px 0px;
    }
    .solicitar-contato {
    margin-top: 40px;
    height: 32px;
    width: 274px;
}

.grafismo-direito {
    background-image: url('@/assets/img/retangulo.svg');
    padding: 96px 65px 73px 13px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 242%;
    color: #fff;
    display: block!important;
    font-size: 20px;
    font-weight: 600;
    line-height: 29px;
}
.grafismo-esquerdo {
    padding: 66px 65px 0px 0px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: block!important;
    color: #2C2C2C;
    font-size: 20px;
    line-height: 29px;
    font-weight: 700;
}
.quinta-secao p {
    font-size: 18px;
    line-height: 26px;
    font-weight: 400;
    color: #fff;
    margin-top: 25px;
    padding-right: 50px;
}
.internas {
    padding: 0px 0px 0px 142px;
}
button#solicite {
    background-color: #FF8523;
    color: #fff;
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
    border: 0px;
    width: 105%;
}
#mobile {
    display: none;
  }
  #desktop {
    display: flex;
  }

    }
@media (max-width: 1199px) { 

    .grafismo-direito {
    background-image: url('@/assets/img/retangulo.svg');
    padding: 96px 65px 73px 13px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0%;
    color: #fff;
    display: block!important;
    font-size: 20px;
    font-weight: 600;
    line-height: 29px;
}
.titulo {
    font-size: 40px;
    font-weight: 700;
    line-height: 42px;
    text-align: right;
    color: #fff;
    margin-right: -77px;
}
#mobile {
    display: none;
  }
  #desktop {
    display: flex;
  }

 }
 @media (max-width: 1024px) { 

    .titulo {
    font-size: 40px;
    font-weight: 700;
    line-height: 42px;
    text-align: right;
    color: #fff;
    margin-right: 0px;
}
.internas {
    padding: 0px 0px 0px 50px;
}
    #mobile {
    display: none;
  }
  #desktop {
    display: flex;
  }
 }
@media (max-width: 992px) { 

    .internas {
    padding: 0px 0px 0px 117px;
}
.grafismo-esquerdo {
    padding: 66px 0px 0px 0px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: block!important;
    color: #2C2C2C;
    font-size: 20px;
    line-height: 29px;
    font-weight: 700;
}
.card {
    position: relative;
    display: block;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff0;
    background-clip: border-box;
    border: 0px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
}
    #mobile {
    display: none;
  }
  #desktop {
    display: block;
  }
  #desktop1 {
    display: flex;
  }
 }
@media (max-width: 768px) { 

    .header {
        background-image: url('@/assets/img/mobile/01.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        margin-top: -90px;
    }
    section.terceira-secao {
        background-image: url('@/assets/img/mobile/03.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        padding-top: 70px;
        padding-bottom: 70px;
    }
       .h1-text {
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
    color: #fff;
    text-align: center;
}
.subtext-header{
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    color: #fff;
    text-align: center;
}
span.text-area {
    font-weight: 700;
    font-size: 20px;
    color: #fff;
    line-height: 26px;
    text-align: center;
}
section#desktop1 {
    display: none;
  }
    #mobile {
    display: block;
  }
  #desktop {
    display: none;
  }
  .rectangle {
    background-color: #ff852300;
    width: 432px;
    height: 440px;
    border-top-right-radius: 80px;
    padding: 49px 50px 50px 70px;
    padding: 50% 50px 50px 70px;
    display: block!important;
}
.grafismo-direito {
    padding: 96px 30px 73px 30px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0%;
    color: #fff;
    display: block!important;
    font-size: 20px;
    font-weight: 600;
    line-height: 29px;
    background-color: #424242;
}
.interna2 {
    margin-top: 50px;
    border-left: 0px solid #ffffff;
}
.internas {
    padding: 0px 0px 0px 0px;
    text-align: center;
}
.interna {
    margin-top: 50px;
    border-left: 0px solid #2C2C2C;
}
.quinta-secao h2 {
    font-size: 20px;
    line-height: 22px;
    font-weight: 700;
    color: #fff;
    text-align: center;
}
.quinta-secao p {
    font-size: 18px;
    line-height: 26px;
    font-weight: 400;
    color: #fff;
    margin-top: 25px;
    padding-right: 0px;
    text-align: center;
}

 }
@media (max-width: 575.98px) { 
    .header {
        background-image: url('@/assets/img/mobile/01.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        margin-top: -90px;
        height: 500px;
        padding: 18px 0px;
    }
    section.terceira-secao {
        background-image: url('@/assets/img/mobile/03.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        padding-top: 70px;
        padding-bottom: 70px;
    }
    .h1-text {
    font-size: 18px;
    font-weight: 700;
    line-height: 23px;
    color: #fff;
    text-align: center;
}
 .rectangle {
    background-color: #ff852300;
    width: 432px;
    height: 440px;
    border-top-right-radius: 80px;
    padding: 120px 50px 50px 50px;
}
.rectangle1 {
    background-color: #ff852300;
    width: 619px;
    height: 598px;
    border-top-right-radius: 80px;
    padding: 100px 50px 50px 34px;
}
  .subtext-header {
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    color: #fff;
    text-align: center;
}
span.text-area {
    font-weight: 700;
    font-size: 20px;
    color: #fff;
    line-height: 26px;
    text-align: center;
}
    #mobile {
    display: block;
  }
  #desktop {
    display: none;
  }
  .internas {
    padding: 0px 30px 0px 30px;
    text-align: center;

}
  .interna {
    margin-top: 50px;
    border-left: 0px solid #2C2C2C;
}
.grafismo-esquerdo {
    padding: 66px 0px 66px 0px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: block!important;
    color: #2C2C2C;
    font-size: 20px;
    line-height: 29px;
    font-weight: 700;
}
.grafismo-direito {
    padding: 96px 30px 73px 30px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0%;
    color: #fff;
    display: block!important;
    font-size: 20px;
    font-weight: 600;
    line-height: 29px;
    background-color: #424242;
}
.interna2 {
    margin-top: 50px;
    border-left: 0px solid #ffffff;
}
.quinta-secao h2 {
    font-size: 20px;
    line-height: 22px;
    font-weight: 700;
    color: #fff;
    text-align: center;
}
.quinta-secao p {
    font-size: 18px;
    line-height: 26px;
    font-weight: 400;
    color: #fff;
    margin-top: 25px;
    padding-right: 0px;
    text-align: center;
}
#button2 {
    color: #FF8523;
    font-size: 16px;
    font-weight: 700;
    background-color: #fff;
    border: 0px;
    text-transform: uppercase;
    display: block;
    margin-top: 50px!important;
}
  .sexta-secao {
    padding: 51px 0px;
}
 }
@media (max-width: 360px) { 

     .header {
        background-image: url('@/assets/img/mobile/01.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        margin-top: -90px;
        height: 500px;
        padding: 18px 0px;
    }
    .grafismo-direito {
    padding: 96px 30px 73px 30px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0%;
    color: #fff;
    display: block!important;
    font-size: 20px;
    font-weight: 600;
    line-height: 29px;
    background-color: #424242;
}
    .h1-text {
    font-size: 17px;
    font-weight: 700;
    line-height: 23px;
    color: #fff;
    text-align: center;
}
.internas {
    padding: 0px 30px 0px 30px;
    text-align: center;

}
.interna2 {
    margin-top: 50px;
    border-left: 0px solid #ffffff;
}
.interna {
    margin-top: 50px;
    border-left: 0px solid #2C2C2C;
}
.grafismo-esquerdo {
    padding: 66px 0px 66px 0px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: block!important;
    color: #2C2C2C;
    font-size: 20px;
    line-height: 29px;
    font-weight: 700;
}
   .rectangle {
    background-color: #ff852300;
    width: 432px;
    height: 440px;
    border-top-right-radius: 80px;
    padding: 120px 50px 50px 50px;
}
.rectangle1 {
    background-color: #ff852300;
    width: 619px;
    height: 598px;
    border-top-right-radius: 80px;
    padding: 0px 15px 50px 15px;
}

section.terceira-secao {
        background-image: url('@/assets/img/mobile/03.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        padding-top: 70px;
        padding-bottom: 70px;
    }
    .subtext-header {
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    color: #fff;
    text-align: center;
}
.quinta-secao p {
    font-size: 18px;
    line-height: 26px;
    font-weight: 400;
    color: #fff;
    margin-top: 25px;
    padding-right: 0px;
    text-align: center;
}
    #mobile {
    display: block;
  }
  #desktop {
    display: none;
  }
  #button2 {
    color: #FF8523;
    font-size: 16px;
    font-weight: 700;
    background-color: #fff;
    border: 0px;
    text-transform: uppercase;
    display: block;
    margin-top: 50px!important;
}
  .sexta-secao {
    padding: 51px 0px;
}
 }

</style>