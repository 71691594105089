<template>
  
  <TopBar />
  <Conteudo />
  <Footer />

</template>

<script>
// @ is an alias to /src
import TopBar from '@/components/TopBar.vue'
import Conteudo from '@/components/Conteudo.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: 'Home',
  components: {
    TopBar,
    Conteudo,
    Footer,
}
}
</script>
