<template>

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content bck">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            <div class="container-fluid">
                <div class="row d-flex">
                    <div class="col-12 align-items-center justify-content-end bckf">
                        <p class="title">
                            Você usa o espaço da sua<br> empresa ao máximo?
                        </p>
                        <p class="subtitle">
                            Amplie a capacidade de estoque<br> com os Porta Pallets e resolva a<br> demanda de armazenagem usando<br>todo espaço disponível de forma<br> segura e funcional.
                        </p>
                        <P class="btn-popup">
                            <a :href=url><Contatobtn class="ctp" titulo="QUERO SABER MAIS" /></a>
                        </P>
                    </div>
                    <div class="col-7 justify-content-start">
                        
                    </div>
                </div>
            </div>
    </div>
  </div>
</div>

</template>

<script>
import Contatobtn from '@/components/Button-solicitar-contato.vue'
export default {
    name: 'Modal',
    data: () => ({
      url: 'https://wa.me/5537998015203'
    }),
    components: {
        Contatobtn
    }
    }
</script>

<style scoped>

    .ctp {
        margin-top: 0px;
        width: 202px;
        height: 32px;
        background-color: #FF8523;
        color: #fff;
        font-weight: 700;
        font-size: 16px;
        border: 0px;
    }

    .bckf {
        padding: 146px 40px;
    }

    p.title {
    color: #FF8523;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
}

    .bck {
        background-image: url('@/assets/img/backmodal.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

 .btn-close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: 0.65em 0.65em;
    color: #fff;
    border: 0;
    border-radius: 0.25rem;
    opacity: 1;
    position: absolute;
    right: -10px;
    text-align: end;
    display: block;
    background-color: #fff;
    border-radius: 100px;
    top: -13px;
}
    .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1pxsolidrgba(0,0,0,.2);
    border-radius: 0.3rem;
    outline: 0;
    height: 492px;
}
.btn-close:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%);
    opacity: 1;
}

@media (min-width: 1441px) {  


}

@media (max-width: 1440px) { 

  #mobile {
    display: none;
  }
  #desktop {
    display: flex;
  }

 }
@media (max-width: 1399px) { 
  #mobile {
    display: none;
  }
  #desktop {
    display: flex;
  }
 }
@media (max-width: 1199px) { 
  #mobile {
    display: none;
  }
  #desktop {
    display: flex;
  }
 }
@media (max-width: 992px) { 

    .bckf {
    padding: 123px 40px;
}
  #mobile {
    display: none;
  }
  #desktop {
    display: flex;
  }
 }
@media (max-width: 768px) {
  #mobile {
    display: none;
  }
  #desktop {
    display: flex;
  }
  }
@media (max-width: 575.98px) { 

  .bck {
        background-image: url('@/assets/img/mobile/modalmob.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 175px 0px 0px 0px;
        height: 608px;
    }
  
  #mobile {
    display: none;
  }
  #desktop {
    display: flex;
  }
 }
@media (max-width: 360px) { 

  .bck {
        background-image: url('@/assets/img/mobile/modalmob.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 175px 0px 0px 0px;
        height: 608px;
    }
  
  #mobile {
    display: none;
  }
  #desktop {
    display: flex;
  }
 }

</style>