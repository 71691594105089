<template>

<Modal />

  <section class="foot" id="desktop">  <!-- Remove the container if you want to extend the Footer to full width. -->
<div class="container">
  <!-- Footer -->
  <footer
          class="text-center text-lg-start text-white"
          style="background-color: #000"
          >
    <!-- Grid container -->
    <div class="container p-4 pb-0">

      <!-- Section: Copyright -->
      <section class="p-3 pt-0">
        <img src="@/assets/img/logorodape.svg" width="225" height="51" style="margin-bottom: 20px;">
        <div class="row d-flex align-items-center">
          <!-- Grid column -->
          <div class="col-md-7 col-lg-6 text-center text-md-start">
            <!-- Copyright -->
            <!-- Facebook -->
              
            <a :href="in" target="_blank"
               class="btn btn-floating m-0"
               role="button"
               ><img src="@/assets/img/in.svg"></a>

            <!-- Twitter -->
            <a :href="youtube" target="_blank"
               class="btn btn-floating m-0"
               role="button"
               ><img src="@/assets/img/youtube.svg"></a>

            <!-- Google -->
            <a :href="facebook" target="_blank"
               class="btn btn-floating m-0"
              
               ><img src="@/assets/img/facebook.svg"></a>

            <!-- Instagram -->
            <a :href="whatsapp" target="_blank"
               class="btn btn-floating m-0"
              
               role="button"
               ><img src="@/assets/img/whatsapp.svg"></a>

               <a :href="instagram" target="_blank"
               class="btn btn-floating m-0"
              
               role="button"
               ><img src="@/assets/img/instagram.svg"></a>
            <!-- Copyright -->
          </div>
          <!-- Grid column -->

          <!-- Grid column -->
          <div class="col-md-4 col-lg-6 text-center text-md-end">
            <!-- Facebook -->
            <a :href="whatsapp"><img src="@/assets/img/whatsapp.svg">(37) 99801-5203</a>
           <p class="contatos">amapa@amapa.ind.br</p>
           <p>MG-260, s/n - Anel Rodoviário, Cláudio -<br>MG, 35530-000</p>  
            <!-- Twitter -->
          </div>
          <!-- Grid column -->
        </div>
      </section>
      <!-- Section: Copyright -->
    </div>
    <!-- Grid container -->
  </footer>
  <!-- Footer -->

</div>

<!-- End of .container -->
</section>

<section class="foot" id="mobile">  <!--  -->
<div class="container">
  <!-- Footer -->
  <footer
          class="text-center text-lg-start text-white"
          style="background-color: #000"
          >
    <!-- Grid container -->
    <div class="container p-4 pb-0">

      <!-- Section: Copyright -->
      <section class="p-3 pt-0">
        <img src="@/assets/img/logorodape.svg" width="225" height="51" style="margin-top: 50px;margin-bottom: 50px;">
        <div class="row d-flex align-items-center">
          <!-- Grid column -->
          <div class="col-md-3 col-lg-6 text-center text-md-end">
            <!-- Facebook -->
           (37) 99801-5203
           <p class="contatos">amapa@amapa.ind.br</p>
           <p class="rodape-contato">MG-260, s/n - Anel<br> Rodoviário, Cláudio - MG,<br> 35530-000</p>  
            <!-- Twitter -->
          </div>
          <!-- Grid column -->
        </div>
        <div class="col-md-7 col-lg-6 text-center text-md-start">
            <!-- Copyright -->
            <!-- Facebook -->
            
            <a :href="in" target="_blank"
               class="btn btn-floating m-0"
               ><img src="@/assets/img/in.svg"></a>

            <!-- Twitter -->
            <a :href="youtube" target="_blank"
               class="btn btn-floating m-0"
               role="button"
               ><img src="@/assets/img/youtube.svg"></a>

            <!-- Google -->
            <a :href="facebook" target="_blank"
               class="btn btn-floating m-0"
              
               ><img src="@/assets/img/facebook.svg"></a>

            <!-- Instagram -->
            <a :href="whatsapp" target="_blank"
               class="btn btn-floating m-0"
              
               role="button"
               ><img src="@/assets/img/whatsapp.svg"></a>

               <a :href="instagram" target="_blank"
               class="btn btn-floating m-0"
               ><img src="@/assets/img/instagram.svg"></a>
            <!-- Copyright -->
          </div>
      </section>
      <!-- Section: Copyright -->
    </div>
    <!-- Grid container -->
  </footer>
  <!-- Footer -->
</div>
<!-- End of .container -->

</section>
     
</template>

<script>
import Modal from '@/components/Modal.vue'
export default {
    name: 'Footer',
    data: () => ({
       facebook: 'https://www.facebook.com/amapainstalacoes',
       instagram: 'https://www.instagram.com/amapa.ind/',
       in: 'https://www.linkedin.com/company/amapainstalacoes/',
       youtube: 'https://www.youtube.com/channel/UCeANbgjKNMyWbMythulbUlA',
       whatsapp: 'https://wa.me/5537998015203',

    }),
    components: {
      Modal
    }
}
</script>

<style scoped>

section.foot {
    background-color: #000;
    height: 434px;
    padding: 100px 0px;
}
.btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.3rem 0.2rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.contatos {
    padding: 10px 0px;
}
.btn-check:focus+.btn, .btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%);
}

@media (min-width: 1440px) { 

  #mobile {
    display: none;
  }
  #desktop {
    display: flex;
  }

}

@media (max-width: 1440px) { 

  #mobile {
    display: none;
  }
  #desktop {
    display: flex;
  }

 }
@media (max-width: 1399px) { 
  #mobile {
    display: none;
  }
  #desktop {
    display: flex;
  }
 }
@media (max-width: 1199px) { 
  #mobile {
    display: none;
  }
  #desktop {
    display: flex;
  }
 }
@media (max-width: 992px) { 
  #mobile {
    display: none;
  }
  #desktop {
    display: flex;
  }
 }
@media (max-width: 768px) {
  section.foot {
    background-color: #000;
    height: auto;
    padding: 50px 0px;
}
p.rodape-contato {
    padding: 0px 0px 50px 0px;
}
.contatos {
    padding: 50px 0px;
}
  #mobile {
    display: block;
  }
  #desktop {
    display: none;
  }
  }
@media (max-width: 575.98px) { 
   section.foot {
    background-color: #000;
    height: auto;
    padding: 50px 0px;
}
.contatos {
    padding: 50px 0px;
}
p.rodape-contato {
    margin: 0px 0px 50px 0px;
}

 #mobile {
    display: block;
  }
  #desktop {
    display: none;
  }
 }
@media (max-width: 360px) { 
  section.foot {
    background-color: #000;
    height: auto;
    padding: 50px 0px;
}
p.rodape-contato {
    margin: 50px 0px 0px 0px;
}
  #mobile {
    display: block;
  }
  #desktop {
    display: none;
  }
 }

</style>