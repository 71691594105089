<template>
        <section class="distribuição" id="desktop">
            <div class="row d-flex gap-4">
                <div class="col-12 col-sm-4 col-md-5 col-lg-2 col-xl-2 d-flex justify-content-center justify-content-lg-center">
                    <div class="card" style="width: 10rem;">
                        <img src="@/assets/img/layout.jpg" class="card-img-top" alt="">
                        <div class="card-body">
                            <h5 class="card-title">Layout<br> Organizado</h5>
                        </div>
                    </div>

                </div>
                <div class="col-12 col-sm-4 col-md-5 col-lg-2 col-xl-2 d-flex justify-content-center justify-content-lg-end">
                    <div class="card" style="width: 10rem;">
                        <img src="@/assets/img/2.svg" class="card-img-top" alt="">
                        <div class="card-body">
                            <h5 class="card-title">Exposição<br>Atrativa</h5>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-4 col-md-5 col-lg-2 col-xl-2 d-flex justify-content-center justify-content-lg-end">
                    <div class="card" style="width: 10rem;">
                        <img src="@/assets/img/3.svg" class="card-img-top" alt="">
                        <div class="card-body">
                            <h5 class="card-title">Armazenagem<br>Prática</h5>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-4 col-md-5 col-lg-2 col-xl-2 d-flex justify-content-center justify-content-lg-end">
                    <div class="card" style="width: 10rem;">
                        <img src="@/assets/img/4.svg" class="card-img-top" alt="">
                        <div class="card-body">
                            <h5 class="card-title">Fácil Retirada<br> de Produtos</h5>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-4 col-md-5 col-lg-2 col-xl-2 d-flex justify-content-end justify-content-md-center justify-content-lg-end">
                    <div class="card" style="width: 10rem;">
                        <img src="@/assets/img/5.svg" class="card-img-top" alt="">
                        <div class="card-body">
                            <h5 class="card-title">Melhores condições de<br>segurança de operação do depósito</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row gap-4 icons">
                <div class="col-12 col-sm-4 col-md-5 col-lg-2 col-xl-2 d-flex justify-content-center justify-content-lg-center">
                    <div class="card" style="width: 10rem;">
                        <img src="@/assets/img/6.svg" class="card-img-top" alt="">
                        <div class="card-body">
                            <h5 class="card-title">Reduza custos<br> de mão de obra</h5>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-4 col-md-5 col-lg-2 col-xl-2 d-flex justify-content-center justify-content-lg-end">
                    <div class="card" style="width: 10rem;">
                        <img src="@/assets/img/7.svg" class="card-img-top" alt="">
                        <div class="card-body">
                            <h5 class="card-title">Reduza perda de materiais por<br> avarias</h5>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-4 col-md-5 col-lg-2 col-xl-2 d-flex justify-content-center justify-content-lg-end">
                    <div class="card" style="width: 10rem;">
                        <img src="@/assets/img/8.svg" class="card-img-top" alt="">
                        <div class="card-body">
                            <h5 class="card-title">Aumente a organização e controle de armazenagem</h5>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-4 col-md-5 col-lg-2 col-xl-2 d-flex justify-content-center justify-content-lg-end">
                    <div class="card" style="width: 10rem;">
                        <img src="@/assets/img/9.svg" class="card-img-top" alt="">
                        <div class="card-body">
                            <h5 class="card-title">Aumente eficiência logística na movimentação interna</h5>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-4 col-md-5 col-lg-2 col-xl-2 d-flex justify-content-end justify-content-md-center justify-content-lg-end">
                    <div class="card" style="width: 10rem;">
                        <img src="@/assets/img/10.svg" class="card-img-top" alt="">
                        <div class="card-body">
                            <h5 class="card-title">Verticalize estoque pesado e facilite o movimento</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row solicitem">
                <div class="col-1 col-sm-0 col-md-0">
                </div>
                    <div class="col-6 col-sm-12 col-md-5 d-flex justify-content-md-center">
                        <a :href=url><ButtonSolicitarContatoVue id="solicite" 
                    titulo="SOLICITAR CONTATO DA AMAPÁ" /></a>
                </div>
                    <div class="col-6 col-sm-12 col-md-5 d-flex justify-content-md-start">
                        <a :href=url><ButtonSolicitarContatoVue id="solicitar" 
                    titulo="SOLICITAR ORÇAMENTO"
                    /></a>
                </div>
                <div class="col-1 col-sm-0 col-md-0">
                </div>
            </div>
        </section>
        <section class="distribuição" id="mobile">
            <div class="container">
            <div class="row d-flex">
                <div class="col-12 d-flex justify-content-center">
                    <div class="card" style="width: 10rem;">
                        <img src="@/assets/img/layout.jpg" class="card-img-top" alt="">
                        <div class="card-body">
                            <h5 class="card-title">Layout<br> Organizado</h5>
                        </div>
                    </div>

                </div>
                <div class="col-12 d-flex justify-content-center">
                    <div class="card" style="width: 10rem;">
                        <img src="@/assets/img/2.svg" class="card-img-top" alt="">
                        <div class="card-body">
                            <h5 class="card-title">Exposição<br>Atrativa</h5>
                        </div>
                    </div>
                </div>
                <div class="col-12 d-flex justify-content-center">
                    <div class="card" style="width: 10rem;">
                        <img src="@/assets/img/3.svg" class="card-img-top" alt="">
                        <div class="card-body">
                            <h5 class="card-title">Armazenagem<br>Prática</h5>
                        </div>
                    </div>
                </div>
                <div class="col-12 d-flex justify-content-center">
                    <div class="card" style="width: 10rem;">
                        <img src="@/assets/img/4.svg" class="card-img-top" alt="">
                        <div class="card-body">
                            <h5 class="card-title">Fácil Retirada<br> de Produtos</h5>
                        </div>
                    </div>
                </div>
                <div class="col-12 d-flex justify-content-center">
                    <div class="card" style="width: 10rem;">
                        <img src="@/assets/img/5.svg" class="card-img-top" alt="">
                        <div class="card-body">
                            <h5 class="card-title">Melhores condições de<br>segurança de operação do depósito</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row icons">
                <div class="col-12 d-flex justify-content-center">
                    <div class="card" style="width: 10rem;">
                        <img src="@/assets/img/6.svg" class="card-img-top" alt="">
                        <div class="card-body">
                            <h5 class="card-title">Reduza custos<br> de mão de obra</h5>
                        </div>
                    </div>
                </div>
                <div class="col-12 d-flex justify-content-center">
                    <div class="card" style="width: 10rem;">
                        <img src="@/assets/img/7.svg" class="card-img-top" alt="">
                        <div class="card-body">
                            <h5 class="card-title">Reduza perda de materiais por<br> avarias</h5>
                        </div>
                    </div>
                </div>
                <div class="col-12 d-flex justify-content-center">
                    <div class="card" style="width: 10rem;">
                        <img src="@/assets/img/8.svg" class="card-img-top" alt="">
                        <div class="card-body">
                            <h5 class="card-title">Aumente a organização e controle de armazenagem</h5>
                        </div>
                    </div>
                </div>
                <div class="col-12 d-flex justify-content-center">
                    <div class="card" style="width: 10rem;">
                        <img src="@/assets/img/9.svg" class="card-img-top" alt="">
                        <div class="card-body">
                            <h5 class="card-title">Aumente eficiência logística na movimentação interna</h5>
                        </div>
                    </div>
                </div>
                <div class="col-12 d-flex justify-content-center">
                    <div class="card" style="width: 10rem;">
                        <img src="@/assets/img/10.svg" class="card-img-top" alt="">
                        <div class="card-body">
                            <h5 class="card-title">Verticalize estoque pesado e facilite o movimento</h5>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <div class="row solicitem">
                    <div class="col-12 d-flex justify-content-center">
                        <a :href=url><ButtonSolicitarContatoVue id="solicite" 
                    titulo="SOLICITAR CONTATO DA AMAPÁ" /></a>
                </div>
                    <div class="col-12 d-flex justify-content-center">
                    <a :href=url><ButtonSolicitarContatoVue id="solicitar" 
                    titulo="SOLICITAR ORÇAMENTO"
                    /></a>
                </div>
            </div>
        </section>
</template>

<script>
import ButtonSolicitarContatoVue from '@/components/Button-solicitar-contato.vue';
import ButtonSolicitarOrcamento from '@/components/Button-solicitar-orcamento.vue';
export default {
    name: 'CentroDistribuicao',
    data: () => ({
      url: 'https://wa.me/5537998015203'
    }),
    components: {
        ButtonSolicitarContatoVue,
        ButtonSolicitarOrcamento
    }
}
</script>

<style scoped>

.icons {
    margin-top: 30px;
}

.card-title {
    margin-bottom: 0.5rem;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
}
.card-body {
    flex: 1 1 auto;
    padding: 1rem 0rem;
}
.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff0;
    background-clip: border-box;
    border: 0px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
}.row.solicitem {
    margin-bottom: 100px;
}button#solicite {
    background-color: #FF8523;
    color: #fff;
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
    border: 0px;
}
button#solicitar {
    color: #2C2C2C;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    width: 234px;
    height: 32px;
}

@media (min-width: 1440px) { 

section#desktop {
    display: block;
}

  section#mobile {
    display: none;
  }
}

@media (max-width: 1440px) { 

  #mobile {
    display: none;
  }
  #desktop {
   display: block;
  }

 }
@media (max-width: 1399px) { 
  #mobile {
    display: none;
  }
  #desktop {
    display: block;
  }
  .solicitar-contato {
    margin-top: 40px;
    height: 32px;
    width: 272px;
}
 }
@media (max-width: 1199px) { 
  #mobile {
    display: none;
  }
  #desktop {
    display: block;
  }
 }
@media (max-width: 992px) { 
  #mobile {
    display: none;
  }
  #desktop {
    display: block;
  }
 }
@media (max-width: 768px) {
  #mobile {
    display: block;
  }
  #desktop {
    display: none;
  }
  .solicitar-contato {
    margin-top: 40px;
    height: 32px;
    width: 280px;
}
  }
@media (max-width: 575.98px) { 
  #mobile {
    display: block;
  }
  #desktop {
    display: none;
  }
 }
@media (max-width: 360px) { 
  #mobile {
    display: block;
  }
  #desktop {
    display: none;
  }
 }
</style>